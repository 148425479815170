import Nav from './components/nav.js';
import Accordion from './components/accordion.js';
import Page from './shared/page.js';
import SwiperSlider from './swiper/swiper-slider';
import Gallery from './components/gallery.js';
import Category from './components/category.js';
import Billboard from './components/billboard.js';

export default class Main extends Page {

	constructor(el) {
		super(el);
		this.init();
		this.pageInit();
	}

	pageInit() {
		
	};

}

window.onload = () => {
	const main = new Main();
	const nav = new Nav();
	const category = new Category();
	const swiper = SwiperSlider.init();
	const gallery = new Gallery();
	const billboard = new Billboard;
	const accordion = new Accordion();
};