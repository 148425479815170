export default class Category {

    constructor() {
        this.categoryRows = document.querySelectorAll('.mod-list__row');

        this.init();
    }

    setMaxHeight(rowId) {
        var row = document.getElementById(rowId);
        if(row){
            var headerElements = row.getElementsByClassName('mod-list__header');
        }

        // Trova l'altezza massima tra i mod-list__header nel row
        var maxHeight = 0;
        if(headerElements){
            for (var i = 0; i < headerElements.length; i++) {
                var headerHeight = headerElements[i].offsetHeight;
                maxHeight = Math.max(maxHeight, headerHeight);
            }
        

            // Imposta l'altezza minima solo per gli elementi che non raggiungono l'altezza massima
            for (var i = 0; i < headerElements.length; i++) {
                if (headerElements[i].offsetHeight < maxHeight) {
                    headerElements[i].style.minHeight = maxHeight + "px";
                }
            }
        }
    }
    
    init() {
        this.categoryRows.forEach((row) => {
            this.setMaxHeight(row.id);
        });
    }

}