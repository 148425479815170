export default class Nav {

    constructor() {
        this.selector = document.querySelector('.item-nav');
        this.toggleBtn = document.querySelectorAll('.item-nav');

        this.burger = document.querySelector('.header__toggle');
        this.menu = document.querySelector('.header__menu')
        this.subnav = document.querySelectorAll('.sub-menu')

        this.isHome = document.querySelector('body').classList.contains('page-homepage')
        //if (!this.el) return;
        this.addListeners();
        this.init();
    }
    

    burger_open() {
        this.menu.classList.add('active');
        this.toggleBtn.classList.add('active');
        document.querySelector('body').classList.add('toggle');
    }

    burger_close() {
        this.menu.classList.remove('active')
        this.toggleBtn.classList.remove('active');
        this.selector.classList.remove('open');
        document.querySelector('body').classList.remove('toggle');
        this.subnav.forEach((item) => {
            item.classList.remove('sub-menu-open')
        })
    }

    toggleBurger(e) {
        e.preventDefault();
        if (this.menu.classList.contains('active')) {
            this.burger_close();
        } else {
            this.burger_open();
        }
    }

    submenu_open(target) {
        let subnav = target.querySelector('.sub-menu');
        subnav.classList.add('sub-menu-open');
        target.classList.add('open');
    }
    
    submenu_close(target) {
        let subnav = target.querySelector('.sub-menu');
        subnav.classList.remove('sub-menu-open');
        target.classList.remove('open');
    }
    
    submenu_close_all() {
        let subnav = document.querySelector('.sub-menu-open');
		subnav.classList.remove('sub-menu-open');
    }

    addListeners() {
        const header = document.querySelector('.header');
        
        let scrollpos = window.scrollY
        const header_height = header.offsetHeight
        window.addEventListener('scroll', function() {
            scrollpos = window.scrollY;

            if (scrollpos >= header_height) { 
                header.classList.add("sticky");
            } else { 
                header.classList.remove('sticky') 
            }
        });


        this.toggleBtn.forEach((button) => {
            button.addEventListener('click', () => {
                let subnav = button.querySelector('.sub-menu');
                let subnavOpen = document.querySelectorAll('.sub-menu-open');
                
                if (subnav !== null && subnav !== undefined) {

                    if (subnav.classList.contains('sub-menu-open') ) {
                        this.submenu_close(button);
                    } else {
                        // Chiudi tutti i sottomenu aperti e rimuovi la classe open da tutte le voci toggleBtn
                        subnavOpen.forEach((item) => {
                            this.submenu_close(item.parentNode);
                        });
            
                        // Apri il sottomenu corrente e aggiungi la classe open alla voce toggleBtn corrispondente
                        this.submenu_open(button);
                    }
                }
            });
        });

	}
    
    init() {
        this.toggleBtn = document.querySelector('[js-burger-toggle]','.header__toggle');
        if(this.toggleBtn) {
            this.toggleBtn.addEventListener('click', this.toggleBurger.bind(this));
        }
    }

}