export default class Billboard {

    constructor() {
        this.puntoCliccato = null;
        this.addListeners();
    }

    addListeners() {
        var immagineElement = document.getElementById('immagine');

        if (immagineElement) {

            immagineElement.addEventListener('click', (event) => {
            const x = event.clientX;
            const y = event.clientY;
    
            const puntoCliccato = this.trovaPunto(x, y);
    
            if (puntoCliccato) {
                this.mostraPopup(puntoCliccato);

                if (this.puntoCliccato !== puntoCliccato) {
                    this.disattivaPuntoAttivo();
                    this.attivaPunto(puntoCliccato);
                } else {
                    this.disattivaPuntoAttivo();
                }

            }

        });

        }
    }
    
    trovaPunto(x, y) {
        const punti = document.querySelectorAll('.punto');
    
        for (const punto of punti) {
          const rect = punto.getBoundingClientRect();
    
          if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
            return punto.getAttribute('data-id');
          }
        }
    
        return null;
    }
    
    mostraPopup(idPunto) {
        this.nascondiPopup();
    
        const popup = document.getElementById(idPunto);
        if (popup) {
          popup.style.opacity = 1;
        }
    }
    
    nascondiPopup() {
        const popups = document.querySelectorAll('.popup');
        popups.forEach((popup) => {
          popup.style.opacity = 0.4;
        });
    }

    attivaPunto(idPunto) {
        this.puntoCliccato = idPunto;
        const puntoAttivo = document.querySelector(`.punto[data-id="${idPunto}"]`);
        if (puntoAttivo) {
            puntoAttivo.classList.add('active');
            puntoAttivo.style.pointerEvents = 'none'; // Blocca gli eventi del mouse sull'elemento
        }
    }

    disattivaPuntoAttivo() {
        if (this.puntoCliccato) {
          const puntoAttivo = document.querySelector(`.punto[data-id="${this.puntoCliccato}"]`);
          if (puntoAttivo) {
            puntoAttivo.classList.remove('active');
            puntoAttivo.style.pointerEvents = 'auto'; // Riabilita gli eventi del mouse sull'elemento
          }
          this.puntoCliccato = null;
        }
    }

}