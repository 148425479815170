
export default class Accordion {

	constructor() {

		this.accordionBtns = document.querySelector(".accordion-button");
		this.accordionCtns = document.querySelector(".accordion-content");
		
		this.addListeners();
	}

	addListeners(){
		if(this.accordionBtns != null) {
			this.accordionBtns.addEventListener("click", () => {
				if (this.accordionCtns.classList.contains("open")) {
					
					this.accordionCtns.classList.remove("open");
					this.accordionBtns.classList.remove('arrow-rotate');
				} else {
					this.accordionCtns.classList.add("open");
					this.accordionBtns.classList.add("arrow-rotate");
				}
				
			});
		}
    }

}