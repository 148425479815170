import Swiper, { Navigation, Pagination, Autoplay, A11y, EffectCreative, Mousewheel } from 'swiper';
import BreakPoints from '../shared/breakpoints';
import SwiperSettings from './swiper-settings';
export default class SwiperSlider extends BreakPoints {

	constructor(node) {
		super();
		this.node = node;
		this.id = this.node.dataset.swiperId;
		this.type = this.node.dataset.swiperSlider;
		this.params = SwiperSettings.getParams(this.type);
		this.features = SwiperSettings.getFeatures(this.type);
		console.log(this.features);
		this.init();
	}

	init() {
		const params = this.features.mobileVersion && this.isMobile === true 
					 ? this.features.mobileVersion
					 : this.type;

		this.params = SwiperSettings.getParams(params)

		if(this.features.titlechange) {
			
			const wrapper = this.node.querySelector('.hero-homepage__content_wrapper');
			const titles = wrapper.querySelectorAll('.title');

			const subWrapper = this.node.querySelector('.hero-homepage__subcontent_wrapper');
			console.log(subWrapper);
			const subTitles = subWrapper.querySelectorAll('.subtitle');
			console.log(subTitles);


			const navWrapper = this.node.querySelector('.wrapper');
			const navTitles = navWrapper.querySelectorAll('.nav-title');

			let titleHeight = [];
			titles.forEach(element => {
				titleHeight.push(element.offsetHeight)
			});

			let subTitleHeight = [];
			subTitles.forEach(element => {
				subTitleHeight.push(element.offsetHeight)
			});

			let navTitleHeight = [];
			navTitles.forEach(element => {
				navTitleHeight.push(element.offsetHeight)
			});

			let titlePosition = [];
			titlePosition = titleHeight.map((elem, index) => titleHeight.slice(0,index + 1).reduce((a, b) => a + b));
			console.log(titlePosition);

			let subTitlePosition = [];
			subTitlePosition = subTitleHeight.map((elem, index) => subTitleHeight.slice(0,index + 1).reduce((a, b) => a + b));
			console.log(subTitlePosition);

			let navTitlePosition = [];
			navTitlePosition = navTitleHeight.map((elem, index) => navTitleHeight.slice(0,index + 1).reduce((a, b) => a + b));
			console.log(navTitlePosition);

			wrapper.style.height = titleHeight[0] + 'px';
			subWrapper.style.height = subTitleHeight[0] + 'px';
			navWrapper.style.height = navTitleHeight[0] + 'px';
			
			this.params = Object.assign(this.params, {
				on: {
					slideChange: (swiper) => {
						let content = wrapper.querySelector('.content' );
						let subContent = subWrapper.querySelector('.subcontent' );
						let navContent = navWrapper.querySelector('.nav-content' );

						if( ( swiper.previousIndex - swiper.realIndex ) < 0 ) {

							console.log('up ->' + swiper.realIndex);				
							content.style.transform = 'translate( 0px, '+ ' -' + titlePosition[swiper.realIndex - 1] + 'px)';
							content.style.height = titleHeight[swiper.realIndex] + 'px';						
							wrapper.style.height = titleHeight[swiper.realIndex] + 'px';

							subContent.style.transform = 'translate( 0px, '+ ' -' + subTitlePosition[swiper.realIndex - 1] + 'px)';
							subContent.style.height = subTitleHeight[swiper.realIndex] + 'px';						
							subWrapper.style.height = subTitleHeight[swiper.realIndex] + 'px';

							navContent.style.transform = 'translate( 0px, '+ ' -' + navTitlePosition[swiper.realIndex - 1] + 'px)';
							navContent.style.height = navTitleHeight[swiper.realIndex] + 'px';						
							navWrapper.style.height = navTitleHeight[swiper.realIndex] + 'px';

						} else {	
							console.log('down ->' + swiper.realIndex);
							if(swiper.realIndex != 0) {
								content.style.transform = 'translate( 0px, '+ ' -' + titlePosition[swiper.realIndex - 1]  + 'px)';
								subContent.style.transform = 'translate( 0px, '+ ' -' + subTitlePosition[swiper.realIndex - 1]  + 'px)';
								navContent.style.transform = 'translate( 0px, '+ ' -' + navTitlePosition[swiper.realIndex - 1]  + 'px)';
							} else {
								content.style.transform = 'translate( 0px, 0px)';
								subContent.style.transform = 'translate( 0px, 0px)';
								navContent.style.transform = 'translate( 0px, 0px)';
							}
							content.style.height = titleHeight[swiper.realIndex] + 'px';						
							wrapper.style.height = titleHeight[swiper.realIndex] + 'px';

							subContent.style.height = subTitleHeight[swiper.realIndex] + 'px';						
							subWrapper.style.height = subTitleHeight[swiper.realIndex] + 'px';

							navContent.style.height = navTitleHeight[swiper.realIndex] + 'px';						
							navWrapper.style.height = navTitleHeight[swiper.realIndex] + 'px';
						}
					}
				}
			})
		}

		Swiper.use([Navigation, Pagination, Autoplay, A11y, EffectCreative, Mousewheel ]);
		this.swiper = new Swiper(this.node, this.params);
	}

	onMobile() {
		this.onDestroy();
		this.init();
	} 

	onDesktop() {
		this.onDestroy();
		this.init();
	}

	onDestroy() {
		if (this.swiper !== undefined) {
			this.swiper.destroy(true, true);
		}
	}
		
	static init() {
		SwiperSlider.item = Array.from(document.querySelectorAll('[data-swiper-slider]')).map((node, index) => new SwiperSlider(node, index));

	}

}
