
export default class BreakPoints {

	constructor() {
		this.mediaQuery = '(min-width: 768px)';
		this.setBreakpoints();
	}

	setBreakpoints() {
		this.mediaQueryList = window.matchMedia(this.mediaQuery);
		this.isMobile = this.mediaQueryList.matches === true ? false : true;

		this.mediaQueryList.addEventListener('change', () => {
			this.breakpointChecker();
		});
	}

	breakpointChecker() {
		if (this.mediaQueryList.matches === true) {
			this.isMobile = false;
			this.onDesktop();
		} else if (this.mediaQueryList.matches === false) {
			this.isMobile = true;
			this.onMobile();
		}
	};

	onMobile() {
		//do something on mobile
	}

	onDesktop() {
		//do something on desktop
	}
}
