export default class Gallery {

	constructor() {

        this.grid = document.querySelector('.grid');
		this.slide = document.querySelector('.slide');
		
		this.addListeners();
	}

	addListeners(){
        if(this.grid != null) {
            this.grid.addEventListener("click", () => {
                this.slide.classList.remove("orange");
                this.grid.classList.add("orange");
                document.querySelector('.img-gallery-grid').style.display = 'block';
                document.querySelector('.img-gallery__slide').style.display = 'none';
            });
        }

        if(this.slide != null) {
            this.slide.addEventListener("click", () => {
                this.slide.classList.add("orange");
                this.grid.classList.remove("orange");
                document.querySelector('.img-gallery-grid').style.display = 'none';
                document.querySelector('.img-gallery__slide').style.display = 'block';
            });
        }
    }

}