export default class SwiperSettings {

	static params = {
		sliderHome: {
			direction: 'vertical',
			speed: 1000,
			mousewheel: {
				invert: true,
			},
			effect: "creative",
			creativeEffect: {
			  prev: {
				translate: [0, "-100%", 0],
			  },
			  next: {
				translate: [0, "100%", 0],
			  },
			},
			pagination: {
				el: '.swiper-pagination',
				type: "fraction",
			  },
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		},
		sliderPortfolio: {
			loop: true,
			grabCursor:true,
			slidesPerView: "auto",
			spaceBetween: 10,
			breakpoints: {
				1024: {
				  slidesPerView: 1,
				  autoHeight: true
				},
			},
		},
		sliderAbout: {
			loop: true,
			pagination: {
			  el: '.swiper-pagination',
			  type: "fraction",
			},
			navigation: {
			  nextEl: '.swiper-button-next',
			  prevEl: '.swiper-button-prev',
			},
		},
		sliderModThumb: {
			loop: true,
			spaceBetween: 10,
			slidesPerView: 4,
			freeMode: true,
			watchSlidesProgress: true
		},
		sliderMobile: {
			loop: false,
			slidesPerView: 1,
			autoHeight: true,
			grabCursor:true,
			pagination: {
				el: '.swiper-pagination',
			}
		},
		sliderMod: {
			loop: true,
			autoHeight: true,
			grabCursor:true,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
				var names = [];
				document.querySelectorAll('div[data-img]').forEach(el =>
					names.push(el.dataset.img)
				)
				return '<span class="btn btn--tab tab-link ' + className + '"><img src="'+names[index]+'"</img></span>';
				},
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		},

	}

	static features = {
		sliderHome: {
			titlechange: true
		},
		sliderAbout: {
			mobileVersion: 'sliderAbout'
		},
		sliderPortfolio: {
			mobileVersion: 'sliderPortfolio'
		},
		sliderMod: {
			mobileVersion: 'sliderMod'
		},
		sliderModThumb: {
			mobileVersion: 'sliderHome'
		}
	}

	static getParams(key) {
		return Object.assign({}, this.params[key]);
	}

	static getFeatures(key) {
		return this.features[key];
	}

	static get(key) {
		return {
			params: this.getParams(key),
			features: this.getFeatures(key),
		}
	}
}
